exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-android-app-tsx": () => import("./../../../src/pages/android-app.tsx" /* webpackChunkName: "component---src-pages-android-app-tsx" */),
  "component---src-pages-custom-redirect-tsx": () => import("./../../../src/pages/custom-redirect.tsx" /* webpackChunkName: "component---src-pages-custom-redirect-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ios-app-tsx": () => import("./../../../src/pages/ios-app.tsx" /* webpackChunkName: "component---src-pages-ios-app-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sara-e-ricardo-tsx": () => import("./../../../src/pages/sara-e-ricardo.tsx" /* webpackChunkName: "component---src-pages-sara-e-ricardo-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

